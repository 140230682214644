import { iconPath } from '@ui/helpers'
import React from 'react'

type GoodPolicyBadgeProps = {
  prefix_class?: string
  className?: string
  isShowImage?: boolean
  highlightCont?: string
  content?: string
}

export const GoodPolicyBadge = ({
  isShowImage = false,
  className = '',
  prefix_class,
  highlightCont,
  content
}: GoodPolicyBadgeProps) => {
  return (
    <>
      <div className={`${prefix_class} ${className}`}>
        {isShowImage && (
          <img
            className={`${prefix_class}--icon`}
            src={iconPath('shield_check_filled.svg')}
            alt="shield check"
          />
        )}
        <span className={`${prefix_class}--text`}>
          <span className={`${prefix_class}--highlight`}>{highlightCont}</span> {content}
        </span>
      </div>
    </>
  )
}
